import { defineStore } from "pinia";
import { MonitoringFormData as FormData } from "@/core/types/gws-blk/MonitoringFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { formatDateTimeCustom } from "@/core/helpers/date-format";
import { MonitoringListItem } from "@/core/types/gws-blk/MonitoringListItem";
import { LspServiceApplication } from "@/core/types/gws-global-feature/cpmi/LspServiceApplication";

const authStore = useAuthenticationStore();
export const useBlkMonitoringFormStore = defineStore({
  id: "blkMonitoringFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateUpdateId: "",
      stateSelectedCpmi: [] as string[],
      stateLspFormData: {} as LspServiceApplication,
      stateCertificateFile: "",
      stateCertificateDetail: {
        name: "",
        course: "",
      },
      stateUploadType: "",
      stateExamDocs: [] as string[],
      statePayloadCpmiId: "",
      statePayloadMonitoringId: "",
      stateUpdateBlkId: "",
      stateUpdateServiceId: "",
      stateMonitoringData: {} as MonitoringListItem,
      stateLspSubmitCpmiId: "",
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    lspFormData: (state) => {
      return state.stateLspFormData;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          blk_id: authStore.user.org_id,
          services_groups_blks_id: this.formData.services_groups_blks_id,
          instructior_blks: this.formData.instructor_blks,
          cpmi_ids: this.formData.cpmi_ids,
          training_date: formatDateTimeCustom(this.formData.training_date, "YYYY-MM-DD"),
          documentation_files: this.formData.documentation_files,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/group-practice-monitoring`,
          payload as AxiosRequestConfig
        );
        console.log(response);
        if (response.status === 201) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const errMsg = error.response.message;
        this.stateErrors.push(errMsg);
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          blk_id: authStore.user.org_id,
          services_group_blks_id: this.formData.services_groups_blks_id,
          instructior_blks: this.formData.instructor_blks,
          cpmi_ids: this.formData.cpmi_ids,
          training_date: this.formData.training_date,
          documentation_files: this.formData.documentation_files,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.patch(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/group-practice-monitoring`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async submitLspProcess() {
      const payload = {
        service_date: this.stateLspFormData.service_date,
        services_blk_id: this.stateLspFormData.services_blk_id,
        book_hour: this.stateLspFormData.book_hour,
        lsp_id: this.stateLspFormData.lsp_id,
        certificate_package_blk_id: this.stateLspFormData.certificate_package_blk_id,
      }
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateLspSubmitCpmiId}/services-lsp`,
          payload as AxiosRequestConfig
        );
        console.log(response);
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async patchDocs() {
      this.stateIsLoading = true;
      try {
        const payload = {
          blk_id: authStore.user.org_id,
          documentation_photo_files: this.stateExamDocs.toString(),
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.patch(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/group-practice-monitoring/${this.stateUpdateId}`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async uploadCertificate(id: string) {
      this.stateIsLoading = true;
      try {
        const payload = {
          certificate_file: this.stateCertificateFile,
          type: this.stateUploadType,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/group-practice-monitoring/${id}/${this.statePayloadCpmiId}/upload-certificate`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateError = true;
        console.log(error);
        this.stateErrors.push(error.toString());
        // const metaResp = error.response.data.meta as Meta;
        // metaResp.errors[0].forEach((err) => {
        //   this.stateErrors.push(err.message);
        // });
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/blk/${authStore.user.org_id}/services-groups-blk`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {} as FormData;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
