import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { BlkWrapper } from "@/core/types/gws-master/master/blk/BlkWrapper";
import { MonitoringListItem as ListItem } from "@/core/types/gws-blk/MonitoringListItem";
import {
  GroupPracticeMonitoringDetail,
  MonitoringListDetail as ListDetail,
  MonitoringListDetail,
  ServiceGroup,
  SiswaItem,
} from "@/core/types/gws-blk/MonitoringListDetail";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { number } from "yup";
import { ServiceListItem } from "@/core/types/gws-blk/ServiceListItem";
import {
  EventList,
  EventListAttendance,
} from "@/core/types/gws-ap2tki/schedule/EventList";

const authStore = useAuthenticationStore();
export const useBlkMonitoringListStore = defineStore({
  id: "blkMonitoringListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      stateDetailData: {} as ListDetail,
      stateDetailServiceGroups: {} as ServiceGroup,
      stateDetailMonitoringAttendance: [] as EventListAttendance[],
      stateDetailDocumentation: [] as EventListAttendance[],
      stateDetailSiswa: [] as SiswaItem[],
      stateDetailId: "",
      statePaginationLoading: false,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    detailData(state) {
      return state.stateDetailData;
    },
    detailServiceGroup(state) {
      return state.stateDetailServiceGroups;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      authStore.getUserInfo();
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
        blk_id: authStore.user.org_id || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `group-practice-monitoring?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateDisplayData = response.data?.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    clearDetailData() {
      this.stateDetailData = {} as ListDetail;
      this.stateDetailServiceGroups = {} as ServiceGroup;
      this.stateDetailMonitoringAttendance = [];
    },
    async getDetail(id: string) {
      this.stateDetailData = {} as ListDetail;
      this.stateDetailServiceGroups = {} as ServiceGroup;
      try {
        const response: AxiosResponse<ApiResponse<ListDetail>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `group-practice-monitoring/${id}`
          );
        this.stateDetailData = response.data?.data;
        this.stateDetailServiceGroups = response.data?.data.servicesGroupsBlk;
        this.stateDetailMonitoringAttendance = [];
        const rawAttendance =
          response.data?.data.group_practice_monitoring_detail;
          
        await rawAttendance.forEach((el) => {
          const countSiswa = el.cpmi_ids.split(",").length;
          const rawSiswa = el.cpmi;
          const listSiswa = [] as SiswaItem[];
          rawSiswa.forEach((sis) => {
            listSiswa.push({
              id: sis.id,
              nama: `${sis.first_name} ${sis.middle_name} ${sis.last_name}`,
              photo: sis.self_photo_file,
            })
          })
          this.stateDetailMonitoringAttendance.push({
            groupId: el.id,
            allDay: true,
            title: `${countSiswa} Siswa Hadir`,
            start: el.training_date,
            color: "",
            extendedProps: {
              documentation: el.documentation_files,
              siswa: listSiswa,
            },
          });
          this.stateDetailMonitoringAttendance.push({
            groupId: el.id,
            allDay: true,
            title: `Lihat Foto`,
            start: el.training_date,
            color: "",
            extendedProps: {
              documentation: el.documentation_files,
              siswa: listSiswa,
            },
          });
        });
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
